import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Column from "../../components/column"
import Columns from "../../components/columns"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Stack from "../../components/stack"

const MediaguideLeichteSprache = () => {
  const data = useStaticQuery(graphql`
    query MediaguideLeichteSpracheContentQuery {
      leichteSprache: file(
        relativePath: { eq: "mediaguide/leichte-sprache.jpg" }
      ) {
        ...mediumImage
      }
      lesetexte: file(relativePath: { eq: "mediaguide/lesetexte.jpg" }) {
        ...mediumImage
      }
      nummern: file(relativePath: { eq: "mediaguide/nummern.jpg" }) {
        ...mediumImage
      }
      audio: file(relativePath: { eq: "mediaguide/audio.jpg" }) {
        ...mediumImage
      }
      laufstrecke: file(relativePath: { eq: "mediaguide/laufstrecke.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <Stack space={[12, 24]}>
      <Columns collapse={[true, false]} space={12}>
        <Column width={["full", "1/3"]}>
          <Image
            image={data.leichteSprache.childImageSharp.gatsbyImageData}
            alt="Hauptmenü des Media-Guide in leichter Sprache"
          />
        </Column>
        <Column>
          <Paragraph>
            Das Gerät für den Media-Guide können Sie im Museum ausleihen.
            <br />
            Fragen Sie an der Kasse danach.
            <br />
            Den Media­-Guide gibt es auch als App.
            <br />
            Die App können Sie auf Ihr Handy herunter∙laden.
          </Paragraph>
        </Column>
      </Columns>
      <Columns collapse={[true, false]} space={12}>
        <Column width={["full", "1/3"]}>
          <Image
            image={data.audio.childImageSharp.gatsbyImageData}
            alt="Eine Besucherin verwendet den Audio Player des Media-Guide vor einer Vitrine."
          />
        </Column>
        <Column>
          <Stack space={6}>
            <Heading as="h2" level={4}>
              Führung in Leichter Sprache anhören
            </Heading>
            <Paragraph>
              Im Media-Guide gibt es eine <strong>Hör-Führung</strong>. <br />
              Wenn Sie auf dem Bild∙schirm etwas aus∙wählen <br />
              können Sie Texte in Leichter Sprache an∙hören. <br />
              Dafür brauchen Sie Kopf∙hörer. <br />
              Die Kopf∙hörer können Sie im Museum ausleihen.
            </Paragraph>
          </Stack>
        </Column>
      </Columns>
      <Columns collapse={[true, false]} space={12}>
        <Column width={["full", "1/3"]}>
          <Image
            image={data.lesetexte.childImageSharp.gatsbyImageData}
            alt="Media-Guide mit einem Lesetext in leichter Sprache vor einem Objekt aus der Dauerausstellung"
          />
        </Column>
        <Column>
          <Stack space={6}>
            <Heading as="h2" level={4}>
              Ausstellungs∙texte in Leichter Sprache lesen
            </Heading>
            <Paragraph>
              Es gibt auch <strong>Lese∙texte</strong> in Leichter Sprache.{" "}
              <br />
              Wählen Sie auf dem Bild∙schirm einen Raum aus. <br />
              Dann bekommen Sie eine Liste mit allen Lese­∙texten in Leichter
              Sprache.
            </Paragraph>
          </Stack>
        </Column>
      </Columns>
      <Columns collapse={[true, false]} space={12}>
        <Column width={["full", "1/3"]}>
          <Image
            image={data.nummern.childImageSharp.gatsbyImageData}
            alt="Nummerneingabe mit der Tastatur des Media-Guide. Im Hintergrund ein Objekt mit der Nummer 701"
          />
        </Column>
        <Column>
          <Paragraph>
            In den Ausstellungs∙räumen gibt es auch Nummern. <br />
            Sie können die Nummer im Media-Guide ein∙geben. <br />
            Dann bekommen Sie den passenden Text in Leichter Sprache.
          </Paragraph>
        </Column>
      </Columns>
      <Columns collapse={[true, false]} space={12}>
        <Column width={["full", "1/3"]}>
          <Image
            image={data.laufstrecke.childImageSharp.gatsbyImageData}
            alt="Laufstrecke im Garten des Hölderlinturm"
          />
        </Column>
        <Column>
          <Stack space={6}>
            <Heading as="h2" level={4}>
              Gedichte hören und spazieren gehen
            </Heading>
            <Paragraph>
              Den Media-Guide können Sie auch im Museums∙Garten nutzen.
              <br />
              Im Garten gibt es einen Weg.
              <br />
              Auf diesem Weg können Sie ein Gedicht an∙hören.
            </Paragraph>
            <Paragraph>
              Mit dem Media-Guide können Sie auswählen:
              <br />
              Das Gedicht wird entweder langsam vor∙gelesen. <br />
              Oder in normaler Geschwindigkeit. <br />
              Oder es wird sehr schnell vor∙gelesen.
            </Paragraph>
            <Paragraph>
              Dann können Sie mit dem Gedicht auf dem Weg spazieren gehen.{" "}
              <br />
              Wenn das Gedicht langsam vorgelesen wird, <br />
              haben Sie mehr Zeit bis zum Ende der Strecke. <br />
              Wenn das Gedicht schnell vorgelesen wird, <br />
              müssen Sie sich beeilen,
              <br />
              damit Sie am Ende der Strecke ankommen, <br />
              wenn auch das Gedicht zu Ende ist.
            </Paragraph>
          </Stack>
        </Column>
      </Columns>
    </Stack>
  )
}

export default MediaguideLeichteSprache
